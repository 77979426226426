export default [
    {
        id: "pos_qsgVnf8ixhJItmv3Zw",
        name: "Farmacias -  zona sur",
        description: "Grupo de farmacias de la zona sur",
        visibility: 2,
        created: "2023-10-02T17:51:13.773994-03:00",
        updated: "2023-10-02T18:34:40.801629-03:00",
        pos: [
            'pos_0XRfqPWqHQPenjBqRZ', 'pos_onyEhgI4eTonrxueoK', 'pos_tgarV16ij64fatfFKY', 'pos_VcYeSMFEU9QlxJtbvq', 'pos_SYVWflXI5Ao6b0bFiU', 'pos_N8a0p2xHJ2TvYmjzkC', 'pos_qFwKXM8JVpMgXCWoSd', 'pos_GhfFXZ4KjquCTx91rY', 'pos_SsXroCIty0WyvVz4lo', 'pos_bTcHtCQSE88GGQqxSh', 'pos_4ZcstGPUOzHPOoRbOA', 'pos_QMq55vV3QqtpFuPEqV', 'pos_yhpTMdrdKAoWbdyWd2', 'pos_we1kIZ067UMPFEE8YG', 'pos_OdZpszc9SbQXkwocfV', 'pos_Bdg9MKcLi1J4dx7sAj', 'pos_2fLSYs5BqkTo1vHKxr', 'pos_q1DDyb2K60kKnR6Ki7', 'pos_sfcZfWJpcHevzuQ45o'
        ],
        members: [
            "mem_RXkdY1aAKnfkbezVyM"
        ]
    },
    {
        id: "pos_tqvZFBPwq86mbfIg2V",
        name: "Farmacias - zona oriente",
        description: "Grupo de farmacias de la zona oriente",
        visibility: 2,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: [
            'pos_0XRfqPWqHQPenjBqRZ', 'pos_onyEhgI4eTonrxueoK', 'pos_tgarV16ij64fatfFKY', 'pos_VcYeSMFEU9QlxJtbvq', 'pos_SYVWflXI5Ao6b0bFiU', 'pos_N8a0p2xHJ2TvYmjzkC', 'pos_qFwKXM8JVpMgXCWoSd', 'pos_GhfFXZ4KjquCTx91rY', 'pos_SsXroCIty0WyvVz4lo', 'pos_bTcHtCQSE88GGQqxSh', 'pos_4ZcstGPUOzHPOoRbOA', 'pos_QMq55vV3QqtpFuPEqV', 'pos_yhpTMdrdKAoWbdyWd2', 'pos_we1kIZ067UMPFEE8YG', 'pos_OdZpszc9SbQXkwocfV', 'pos_Bdg9MKcLi1J4dx7sAj', 'pos_2fLSYs5BqkTo1vHKxr', 'pos_q1DDyb2K60kKnR6Ki7', 'pos_sfcZfWJpcHevzuQ45o'
        ],
        members: [
            "mem_RXkdY1aAKnfkbezVyM"
        ]
    },
	    {
        id: "pos_tqvZFBPwq86mbfIg2V",
        name: "Farmacias - zona centro",
        description: "Grupo de farmacias de la zona centro",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: [
            'pos_0XRfqPWqHQPenjBqRZ', 'pos_onyEhgI4eTonrxueoK', 'pos_tgarV16ij64fatfFKY', 'pos_VcYeSMFEU9QlxJtbvq', 'pos_SYVWflXI5Ao6b0bFiU', 'pos_N8a0p2xHJ2TvYmjzkC', 'pos_qFwKXM8JVpMgXCWoSd', 'pos_GhfFXZ4KjquCTx91rY', 'pos_SsXroCIty0WyvVz4lo', 'pos_bTcHtCQSE88GGQqxSh', 'pos_4ZcstGPUOzHPOoRbOA', 'pos_QMq55vV3QqtpFuPEqV', 'pos_yhpTMdrdKAoWbdyWd2', 'pos_we1kIZ067UMPFEE8YG', 'pos_OdZpszc9SbQXkwocfV', 'pos_Bdg9MKcLi1J4dx7sAj', 'pos_2fLSYs5BqkTo1vHKxr', 'pos_q1DDyb2K60kKnR6Ki7', 'pos_sfcZfWJpcHevzuQ45o'
        ],
        members: [
            "mem_RXkdY1aAKnfkbezVyM"
        ]
    },
		    {
        id: "pos_tqvZFBPwq86mbfIg2V",
        name: "Farmacias - zona norte",
        description: "Grupo de farmacias de la zona norte",
        visibility: 2,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: [
            'pos_0XRfqPWqHQPenjBqRZ', 'pos_onyEhgI4eTonrxueoK', 'pos_tgarV16ij64fatfFKY', 'pos_VcYeSMFEU9QlxJtbvq', 'pos_SYVWflXI5Ao6b0bFiU', 'pos_N8a0p2xHJ2TvYmjzkC', 'pos_qFwKXM8JVpMgXCWoSd', 'pos_GhfFXZ4KjquCTx91rY', 'pos_SsXroCIty0WyvVz4lo', 'pos_bTcHtCQSE88GGQqxSh', 'pos_4ZcstGPUOzHPOoRbOA', 'pos_QMq55vV3QqtpFuPEqV', 'pos_yhpTMdrdKAoWbdyWd2', 'pos_we1kIZ067UMPFEE8YG', 'pos_OdZpszc9SbQXkwocfV', 'pos_Bdg9MKcLi1J4dx7sAj', 'pos_2fLSYs5BqkTo1vHKxr', 'pos_q1DDyb2K60kKnR6Ki7', 'pos_sfcZfWJpcHevzuQ45o'
        ],
        members: [
            "mem_RXkdY1aAKnfkbezVyM"
        ]
    },
	{
        id: "pos_tqvZFBPwq86mbfIg2V",
        name: "Farmacias - zona poniente",
        description: "Grupo de farmacias de la zona poniente",
        visibility: 1,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: [
            'pos_0XRfqPWqHQPenjBqRZ', 'pos_onyEhgI4eTonrxueoK', 'pos_tgarV16ij64fatfFKY', 'pos_VcYeSMFEU9QlxJtbvq', 'pos_SYVWflXI5Ao6b0bFiU', 'pos_N8a0p2xHJ2TvYmjzkC', 'pos_qFwKXM8JVpMgXCWoSd', 'pos_GhfFXZ4KjquCTx91rY', 'pos_SsXroCIty0WyvVz4lo', 'pos_bTcHtCQSE88GGQqxSh', 'pos_4ZcstGPUOzHPOoRbOA', 'pos_QMq55vV3QqtpFuPEqV', 'pos_yhpTMdrdKAoWbdyWd2', 'pos_we1kIZ067UMPFEE8YG', 'pos_OdZpszc9SbQXkwocfV', 'pos_Bdg9MKcLi1J4dx7sAj', 'pos_2fLSYs5BqkTo1vHKxr', 'pos_q1DDyb2K60kKnR6Ki7', 'pos_sfcZfWJpcHevzuQ45o'
        ],
        members: [
            "mem_RXkdY1aAKnfkbezVyM"
        ]
    }
]