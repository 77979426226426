<template>
  <v-row>
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn class="ml-2" :to="{name: 'MonitorList'}" outlined>
          <v-icon left>mdi-chart-timeline-variant </v-icon>Monitor
        </v-btn>
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" color="primary" v-bind="attrs" v-on="on">
              <v-icon left>mdi-folder </v-icon>Grupos<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" v-for="group in groupsList" :key="group.id" :ripple="false">
              <v-list-item-title class="body-2">{{ group.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
     </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0">
      <div class="position-absolute ma-2" style="z-index: 1">
        <v-card class="ma-8" width="280">
          <v-card-text class="px-5 pt-6">
            <span class="fontBody--text subtitle-1 font-weight-semibold">Selecciona lo que deseas ver en el mapa</span>
            <v-col cols="12" class="pa-0 mt-3">
              <v-checkbox class="my-1 vcheckbox" row label="Ubicación" dense hide-details />
              <v-checkbox class="my-1 vcheckbox" row label="Señal de vida" dense hide-details />
              <v-checkbox class="my-1 vcheckbox" row label="Documentos emitidos" dense hide-details />
              <v-checkbox class="my-1 vcheckbox" row label="Total vendido" dense hide-details />
            </v-col>
          </v-card-text>
        </v-card>

        <v-card class="ma-8" width="280">
          <v-card-text class="px-5 pt-6 pb-7">
            <span class="fontBody--text subtitle-1 font-weight-semibold">Rendimiento histórico</span>
            <v-col cols="12" class="pa-0 mt-3">
              <v-slider
                v-model="slider"
                :height="20"
                class="ma-0"
                track-color="var(--light-blue-grey)"
                thumb-color="white"
                hide-details
                :ripple="false"
                :thumb-size="100"
                :ticks="false"
              />
            </v-col>
            <v-progress-linear class="progress-degrade mt-5" height="8" value="100" />
          </v-card-text>
        </v-card>
      </div>
      <div class="position-absolute ma-2" style="z-index:100; right:-5px; bottom:0">
        <v-card class="ma-8" width="280">
          <v-card-text class="px-5 pt-6">
            <span class="fontBody--text subtitle-1 font-weight-semibold">Simbología</span>
            <v-col cols="12" class="pa-0 mt-3">
              <div>
                <v-avatar class="mr-2" size="10" color="secondary" />
                <span class="mr-2 ml-1 body-1">Ubicación</span>
              </div>
            </v-col>
            <span class="d-block mt-3 mb-2 fontBody--text subtitle-2 font-weight-medium">Números</span>
            <div class="d-flex">
              <div>
                <v-avatar class="pb-1 mr-2 degrade" size="10" />
                <span class="mr-2 ml-1 body-1">Doc. emitidos</span>
              </div>
              <div>
                <v-avatar class="pb-1 mr-2 ml-1 degrade" size="10" />
                <span class="mr-2 ml-1 body-1">Total vendidos</span>
              </div>
            </div>
            <span class="d-block mt-3 mb-2 fontBody--text subtitle-2 font-weight-medium">Señal de vida</span>
            <div class="d-flex">
              <div>
                <v-avatar class="pb-1 mr-2" size="10" color="primary" />
                <span class="mr-2 ml-1 body-1">Activo</span>
              </div>
              <div>
                <v-avatar class="pb-1 mr-2 ml-1" size="10" color="purpleMedium" />
                <span class="mr-2 ml-1 body-1">Inactivo</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <VMapBox :latitude= 13.7013266 :longitude= -89.226622 style="height: calc(100vh - 150px) !important;" />
    </v-col>
  </v-row>
</template>
<script>
import groups from '@/data/groupsList' // example
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import VMapBox from '@/modules/pos/monitor/views/VMapBox'

export default {
  components: {
    VEmptyState,
    MiniHeader,
    VMapBox
  },
  data: () => ({
    slider: 70,
    groupsList: groups,
    breadcrumbs: {
     main: 'Monitor',
     children: []
   }
  })
}
</script>
<style>
.v-slider--horizontal {
  margin-left: 0;
  margin-right: 0;
}
.v-slider__thumb {
  height: 18px;
  width: 18px;
  box-shadow:  4px 4px 15px rgba(69, 90, 100, 0.15);
}

.v-slider--horizontal .v-slider__track-container {
  height: 8px;
  border-radius: 50% !important;
}

.degrade {
  background: linear-gradient(90deg, #BE2D2F 3.63%, #E8A803 50.25%, #88B437 94.96%) !important;
}

.progress-degrade {
  border-radius: 5px;
}
.progress-degrade .primary {
  background: linear-gradient(90deg, #BE2D2F 3.63%, #E8A803 50.25%, #88B437 94.96%) !important;
}

</style>