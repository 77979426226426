
<template>
  <div>
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :center="coordinates"
      :zoom="zoom"
      :showCompass="false"
    >
      <MglMarker :coordinates="item.geometry.coordinates" v-for="(item, i) in list" :key="i" @click="dialog=true">
        <span class="custom-marker" slot="marker"><v-icon size="40" :color="item.geometry.color">mdi-circle</v-icon></span>
        <!-- <MglPopup>
          <VCard>
            <div>Unimarc Los Militares</div>
          </VCard>
        </MglPopup> -->
      </MglMarker>
    </MglMap>
    <!-- dialog new branch -->
    <v-dialog v-model="dialog" width="860" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="px-4" color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Detalle</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialog=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-0 px-4">
          <v-row justify="center" no-gutters>
            <v-col cols="4" class="py-5 px-1">
              <v-col class="px-4 py-0">
                <span class="font-weight-bold subtitle-2 secondary--text">POS</span>
              </v-col>
              <div class="d-flex mt-2">
                <div class="col-6">
                  <v-progress-circular class="progress-circular-pos" rounded :rotate="360" :size="95" :width="10" :value="90" color="primary">
                    <span class="d-block text-h6 secondary--text font-weight-bold">4.066</span>
                  </v-progress-circular>
                </div>
                <div class="col-7 py-5">
                  <div>
                    <v-avatar class="pb-1 mr-2" size="10" color="primary" />
                    <span :class="`${$vuetify.breakpoint.width > 1200 ? 'mr-4' : 'mr-1'}`" class="ml-1 body-2">Activos (3890)</span>
                  </div>
                  <div class="mt-2">
                    <v-avatar class="pb-1 mr-2" size="10" color="var(--light-purple)" />
                    <span class="ml-1 body-2">Inactivos (176)</span>
                  </div>
                  <router-link :to="{name: 'PosListCreate'}" class="d-block mt-5 text-decoration-none primary--text">Ir a POS</router-link>
                </div>
              </div>
            </v-col>
            <v-divider vertical />
            <v-col cols="4" class="py-5 px-1">
              <v-col class="px-4 py-0">
                <span class="font-weight-bold subtitle-2 secondary--text">Señal de vida</span>
              </v-col>
              <div class="d-flex mt-2">
                <div class="col-6">
                  <v-progress-circular class="progress-circular-pos" rounded :rotate="360" :size="95" :width="10" :value="90" color="primary">
                    <span class="d-block text-h6 secondary--text font-weight-bold">4.066</span>
                  </v-progress-circular>
                </div>
                <div class="col-7 py-5">
                  <div>
                    <v-avatar class="pb-1 mr-2" size="10" color="primary" />
                    <span :class="`${$vuetify.breakpoint.width > 1200 ? 'mr-4' : 'mr-1'}`" class="ml-1 body-2">Activos (3890)</span>
                  </div>
                  <div class="mt-2">
                    <v-avatar class="pb-1 mr-2" size="10" color="var(--light-purple)" />
                    <span class="ml-1 body-2">Inactivos (176)</span>
                  </div>
                  <router-link :to="{name: 'PosListCreate'}" class="d-block mt-5 text-decoration-none primary--text">Ir a POS</router-link>
                </div>
              </div>
              </v-col>
            <v-divider vertical />
            <v-col cols="4" class="text-center py-8 px-0">
              <div>
                <v-progress-circular class="progress-circular-pos-error" rounded :rotate="360" :size="95" :width="10" :value="90" color="error">
                  <span class="d-block text-h6 secondary--text font-weight-bold">76%</span>
                </v-progress-circular>
                <span class="d-block align-center secondary--text subtitle-2 mt-2">Riesgo operacional<v-icon right color="secondary" size="16">mdi-information-outline</v-icon></span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-toolbar color="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold"><v-icon size="22" left color="secondary">mdi-chart-timeline-variant</v-icon>Actividad del parque POS</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" color="primary" v-bind="attrs" v-on="on">
                    <v-icon left>mdi-calendar </v-icon>Últimos 7 días<v-icon size="18" right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" :ripple="false">
                    <v-list-item-title class="body-2">Últimos 7 días</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col class="px-8 pt-5">
              <span class="font-weight-bold body-1 secondary--text">Total documentos emitidos</span>
              <v-sheet class="mt-5 transparent" height="130">
                <v-row no-gutters>
                  <v-col cols="6" class="pa-0">
                    <span class="d-block text-h6 font-weight-bold">28.909</span>
                    <span class="d-block subtitle-1">25.714</span>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <div class="mx-auto" style="width: 60%; height: 100px">
                      <chart-line
                        class="mt-n12 ml-n10"
                        axe-type-y="number"
                        :background-color="'transparent'"
                        :chart-data="receivedChartData"
                        chart-id="documentsIssued"
                        :custom-labels="['Anterior', 'Actual']"
                        :height="90"
                        :number-rounded="true"
                        :show-axe-x="false"
                        :show-draw-border-grid-lines-axe-y="false"
                        :show-grid-lines-axe-y="false"
                        :show-point="true"
                        :borderWidth="1"
                        style="position:relative; height: 100%"
                      />
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <span class="success--text body-1 font-weight-bold`">+12.34%</span>
                  <v-icon size="25" color="success">mdi-menu-up</v-icon>
                  <span class="body-2">vs 7 días anteriores</span>
                </div>
                <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
              </v-sheet>
            </v-col>
            <v-divider vertical />
            <v-col class="px-8 pt-5">
              <span class="font-weight-bold body-1 secondary--text">Ticket promedio de venta</span>
              <v-sheet class="mt-5 transparent" height="130">
                <v-row no-gutters>
                  <v-col cols="6" class="pa-0">
                    <span class="d-block text-h6 font-weight-bold">28.909</span>
                    <span class="d-block subtitle-1">25.714</span>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <div class="mx-auto" style="width: 60%; height: 100px">
                      <chart-line
                        class="mt-n12 ml-n10"
                        axe-type-y="number"
                        :background-color="'transparent'"
                        :chart-data="receivedChartData"
                        chart-id="documentsIssued"
                        :custom-labels="['Anterior', 'Actual']"
                        :height="90"
                        :number-rounded="true"
                        :show-axe-x="false"
                        :show-draw-border-grid-lines-axe-y="false"
                        :show-grid-lines-axe-y="false"
                        :show-point="true"
                        :borderWidth="1"
                        style="position:relative; height: 100%"
                      />
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <span class="success--text body-1 font-weight-bold`">+12.34%</span>
                  <v-icon size="25" color="success">mdi-menu-up</v-icon>
                  <span class="body-2">vs 7 días anteriores</span>
                </div>
                <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
              </v-sheet>
            </v-col>
            <v-divider vertical />
            <v-col class="px-8 pt-5">
              <span class="font-weight-bold body-1 secondary--text">Total vendido</span>
              <v-sheet class="mt-5 transparent" height="130">
                <v-row no-gutters>
                  <v-col cols="6" class="pa-0">
                    <span class="d-block text-h6 font-weight-bold">28.909</span>
                    <span class="d-block subtitle-1">25.714</span>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <div class="mx-auto" style="width: 60%; height: 100px">
                      <chart-line
                        class="mt-n12 ml-n10"
                        axe-type-y="number"
                        :background-color="'transparent'"
                        :chart-data="receivedChartData"
                        chart-id="documentsIssued"
                        :custom-labels="['Anterior', 'Actual']"
                        :height="90"
                        :number-rounded="true"
                        :show-axe-x="false"
                        :show-draw-border-grid-lines-axe-y="false"
                        :show-grid-lines-axe-y="false"
                        :show-point="true"
                        :borderWidth="1"
                        style="position:relative; height: 100%"
                      />
                    </div>
                  </v-col>
                </v-row>
                <div class="d-flex align-center">
                  <span class="success--text body-1 font-weight-bold`">+12.34%</span>
                  <v-icon size="25" color="success">mdi-menu-up</v-icon>
                  <span class="body-2">vs 7 días anteriores</span>
                </div>
                <v-btn class="body-2" :to="{ name: 'DocumentsList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog new branch -->
  </div>
</template>
<script>
import Mapbox from "mapbox-gl"
import ChartLine from '@/components/charts/ChartLine'
import { MglMap, MglMarker, MglPopup } from "vue-mapbox"

  export default {
    components: {
      ChartLine,
      MglMap,
      MglMarker,
      MglPopup
    },
    data () {
      return {
        receivedChartData: {
          labels: [
            "Lun",
            "Mar",
            "Mie",
            "Jue",
            "Vie",
            "Sab",
            "Dom"
          ],
          datasets: [
            {
              label: "Anterior",
              backgroundColor: '#0352CC',
              percent_change: '20.84',
              data: [60, 33, 56, 10, 100, 50, 30]
            },
            {
              label: "Actual",
              backgroundColor: '#81007F',
              percent_change: '-90.34',
              data: [50, 5, 22, 80, 10, 30, 50]
            }
          ]
        },
        dialog: false,
        list: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              color: '#88B437',
              coordinates: [-77.01696811677053, -12.097197502845349]
            },
            properties: {
              title: 'Mapbox',
              description: 'Washington, D.C.'
            }
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              color: '#E8A803',
              coordinates: [-77.02141456596604, -12.081614157329241]
            },
            properties: {
              title: 'Mapbox',
              description: 'San Francisco, California'
            }
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              color: '#BE2D2F',
              coordinates: [-77.01114840722079, -12.09704156428755]
            },
            properties: {
              title: 'Mapbox',
              description: 'San Francisco, California'
            }
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              color: '#88B437',
              coordinates: [-77.01597571453236, -12.086770291133867]
            },
            properties: {
              title: 'Mapbox',
              description: 'San Francisco, California'
            }
          }
        ],
        accessToken: 'pk.eyJ1IjoiYXh0ZXJvaWQiLCJhIjoiY2wzb3cybWtnMDE3ZzNkcDZ3eWswaW15eSJ9.JAethI13wvW5-rCDF7VRiA',
        mapStyle: 'mapbox://styles/mapbox/light-v9',
        coordinates: [-77.01696811677053, -12.097197502845349], // [-70.5669692, -33.4023576],
        zoom: 14,
        fullscreenControl: true,
        showZoom: false,
        showCompass: false
        // maxWidth: 60
      }
    },
    created () {
      // We need to set mapbox-gl library here in order to use it in template
      this.mapbox = Mapbox
    }
  }
</script>
<style>
.custom-marker > .v-icon {
  opacity: .6 !important;
  position: relative;
}

.custom-marker::before {
  content: "";
  width: 0;
  height: 0;
  border-radius: 50%;
  border: 4px solid var(--light-grey-text-primary) !important;
  position: absolute;
  opacity: initial;
  z-index: 100;
  margin: 14.5px 0 0 15px;
}
</style>